import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    @import url(${(props) => (props.theme['font']['fontImportUrl'])});
    :root {
        --primary-bgColor: ${(props) => props.theme["primaryBg"]};
        --primary-textColor: ${(props) => props.theme["primaryText"]};
        --secondary-bgColor: ${(props) => props.theme["secondaryBg"]};
        --secondary-textColor: ${(props) => props.theme["secondaryText"]};
        --accentColor: ${(props) => props.theme["accent"]};
        --translucent-bgColor: ${(props) => props.theme["translucentBg"]};
        --primary-button-bgColor: ${(props) => props.theme["primaryButtonBg"]};
        --primary-button-textColor: ${(props) =>
          props.theme["primaryButtonText"]};
        --primary-button-bg-hoverColor: ${(props) =>
          props.theme["primaryButtonBgHover"]};
        --success-button-bgColor: ${(props) => props.theme["successButtonBg"]};
        --success-button-textColor: ${(props) =>
          props.theme["successButtonText"]};
        --success-button-bg-hoverColor: ${(props) =>
          props.theme["successButtonBgHover"]};
        --danger-button-bgColor: ${(props) => props.theme["dangerButtonBg"]};
        --danger-button-textColor: ${(props) =>
          props.theme["dangerButtonText"]};
        --danger-button-bg-hoverColor: ${(props) =>
          props.theme["dangerButtonBgHover"]};
        --warning-button-bgColor: ${(props) => props.theme["warningButtonBg"]};
        --warning-button-textColor: ${(props) =>
          props.theme["warningButtonText"]};
        --warning-button-bg-hoverColor: ${(props) =>
          props.theme["warningButtonBgHover"]};
        --borderButtonColor: ${(props) => props.theme["borderButtonColor"]};
        --loader-colorColor: ${(props) => props.theme["loaderColor"]};
        --header-bgColor: ${(props) => props.theme["headerBgColor"]};
        --header-textColor: ${(props) => props.theme["headerTextColor"]};
        --footer-bgColor: ${(props) => props.theme["footerBgColor"]};
        --footer-textColor: ${(props) => props.theme["footerTextColor"]};
        --banner-bgColor: ${(props) => props.theme["bannerBgColor"]};
        --banner-textColor: ${(props) => props.theme["bannerTextColor"]};
        --logoColor: ${(props) => props.theme["logoColor"]};
        --extremely-light-bgColor: ${(props) =>
          props.theme["extremelyLightBg"]};
        --light-bgColor: ${(props) => props.theme["lightBg"]};


        //other vars
        --border-radius: 3px;
        --box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        //grid vars
        --xs: 576px;
        --sm: 786px;
        --md: 992px;
        --lg: 1200px;
        --xl: 1400px;
        
         //fonts
         --font-family: ${(props) => (props.theme['font']['fontFamily'] || '"Roboto", sans-serif')};

        font-size: 16px;
        @media screen and (max-width: 576px) {
            font-size: 14px;
        }
    }

    body {
        font-family: var(--font-family);
        font-weight: 400;
        font-style: normal;
        background: var(--primary-bgColor);
        color: var(--primary-textColor);
        margin: 0;
        box-sizing: border-box;
        line-height: 1.6;
        word-spacing: 2px;
        letter-spacing: 0.5px;
    }

    h1,h2,h3,h4,h5,h6 {
        margin: 0 0 2rem 0;
        line-height: 1.2;
        font-weight: 600;
    }

    h1{
        font-size: 3rem;
    }

    h2{
        font-size: 2.5rem;
    }

    h3{
        font-size: 2rem;
    }

    h4{
        font-size: 1.5rem;
    }

    h5{
        font-size: 1.25rem;
    }

    h6{
        font-size: 1rem;
    }

    .btn {
        position: relative;
        background-image: none;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
        border: unset;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
        user-select: none;
        touch-action: manipulation;
        text-align: center;
        white-space: nowrap;
        min-height: 44px;
        height: auto;
        border-radius: var(--border-radius);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        padding: 0px 22px;
    }

    .btn-outlined,
    .btn-default {
        background-color: transparent;
        border: solid 2px var(--borderButtonColor);
        color: var(--borderButtonColor);
        &:hover {
            border-color: var(--primary-button-bgColor);
            color: var(--primary-button-bgColor);
        }
    }

    .btn-primary {
        color: var(--primary-button-textColor);
        background-color: var(--primary-button-bgColor);
        border-color: var(--primary-button-bgColor);
        &:hover {
            color: var(--primary-button-textColor);
            background-color: var(--primary-button-bg-hoverColor);
        }
    }

    .btn-success {
        color: var(--success-button-textColor);
        background-color: var(--success-button-bgColor);
        border-color: var(--success-button-bgColor);
        &:hover {
            color: var(--success-button-textColor);
            background-color: var(--success-button-bg-hoverColor);
        }
    }

    .btn-danger {
        color: var(--danger-button-textColor);
        background-color: var(--danger-button-bgColor);
        border-color: var(--danger-button-bgColor);
        &:hover {
            color: var(--danger-button-textColor);
            background-color: var(--danger-button-bg-hoverColor);
        }
    }

    .btn-warning {
        color: var(--warning-button-textColor);
        background-color: var(--warning-button-bgColor);
        border-color: var(--warning-button-bgColor);
        &:hover {
            color: var(--warning-button-textColor);
            background-color: var(--warning-button-bg-hoverColor);
        }
    }

    .alert{
        font-size: 1rem;
        padding: 10px;
        border-radius: var(--border-radius);
    }
    .alert-success{
        background-color: var(--success-button-bgColor);
        color: var(--success-button-textColor);
    }
    .alert-danger{
        background-color: var(--danger-button-bgColor);
        color: var(--danger-button-textColor);
    }
    .alert-warning{
        background-color: var(--warning-button-bgColor);
        color: var(--warning-button-textColor);
    }
    .alert-info{
        background-color: var(--primary-button-bgColor);
        color: var(--primary-button-textColor);
    }
`;
