import { lightColors, darkColors} from './colors';
import { defaultFontFamily, defaultFontImportUrl } from './fonts';

const theme = (
    themeName:string="light",
    fontImportUrl:string,
    fontFamily:string
) => {
    const themes:any = {
        "light": lightColors,
        "dark": darkColors
    };
    return {
        ...themes[themeName],
        font: {
            fontImportUrl: fontImportUrl || defaultFontImportUrl,
            fontFamily: fontFamily || defaultFontFamily
        }, 
    };
}

export default theme;