import React from "react";
import "./src/styles/bootstrap.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ThemeProvider } from "styled-components";
import theme from "./src/themes/theme";
import { GlobalStyles } from "./src/themes/GlobalStyles";

export const wrapPageElement = ({ element, props }) => {
  const { theme: themeName, fontImportUrl, fontFamily } = props.pageContext;
  return (
    <ThemeProvider theme={theme(themeName?.toLowerCase() || "light", fontImportUrl, fontFamily)}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  );
};
